// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import SEO from '../../../components/seo'
import LandingpageNav from '../../../components/Landingpage/LandingpageNav'
import CustomContactBtn from '../../../components/Landingpage/CustomContactBtn'
import Landingpage from '../../../components/Landingpage'
import messages from '../../../messages/pageTitles.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'

import baseStyles from './design.scss'
import baseLandingpageMessages from '../../../components/Landingpage/Landingpage.lang'
import landingMessages from '../../../components/Landingpage/messages/Design/Design.lang'

import { StaticImage } from 'gatsby-plugin-image'

type Props = {}

/* eslint-disable max-len */
const heroImg = (
  <StaticImage src="../../../images/landingpage/heroteaser_background.jpg" alt="" objectFit="cover" className="image" />
)
const descriptionImgSmall1 = (
  <StaticImage src="../../../images/landingpage/working_on_phone.jpg" alt="" objectFit="cover" className="image" />
)
const descriptionImgSmall2 = (
  <StaticImage src="../../../images/landingpage/stopwatch.jpg" alt="" objectFit="cover" className="image" />
)
const benefitsImg = (
  <StaticImage src="../../../images/landingpage/pointing_on_screen.jpg" alt="" objectFit="cover" className="image" />
)
const descriptionImgBig = (
  <StaticImage src="../../../images/landingpage/pinwall.jpg" alt="" objectFit="cover" className="image" />
)
const imgStrapi = (
  <StaticImage src="../../../images/landingpage/postit_wall.jpg" alt="" objectFit="cover" className="image" />
)
/* eslint-enable max-len */

const data = {
  heroTeaser: {
    img: heroImg,
    title: landingMessages.heroTitle,
    subtitle: landingMessages.heroSubtitle
  },
  descriptionTeaser: {
    title: landingMessages.descriptionTitle,
    text: landingMessages.descriptionText,
    displayButton: true,
    button: baseLandingpageMessages.descriptionButton,
    imgBig: descriptionImgBig,
    imgSmall1: descriptionImgSmall1,
    imgSmall2: descriptionImgSmall2
  },
  valueBox: {
    title: baseLandingpageMessages.valueBoxTitle,
    subtitle: landingMessages.valueBoxSubtitle,
    title1: landingMessages.valueBoxTitle1,
    title2: landingMessages.valueBoxTitle2,
    title3: landingMessages.valueBoxTitle3,
    values1: [
      landingMessages.productValues1A,
      landingMessages.productValues1B,
      landingMessages.productValues1C,
      landingMessages.productValues1D
    ],
    values2: [
      landingMessages.productValues2A,
      landingMessages.productValues2B,
      landingMessages.productValues2C,
      landingMessages.productValues2D
    ],
    values3: [
      landingMessages.productValues3A,
      landingMessages.productValues3B,
      landingMessages.productValues3C,
      landingMessages.productValues3D
    ]
  },
  benefitsTeaser: {
    img: benefitsImg,
    title: landingMessages.benefitsTitle,
    text: landingMessages.benefitsText,
    button: baseLandingpageMessages.benefitsButton,
    list: [
      landingMessages.benefitsItemA,
      landingMessages.benefitsItemB,
      landingMessages.benefitsItemC,
      landingMessages.benefitsItemD,
      landingMessages.benefitsItemE,
      landingMessages.benefitsItemF,
      landingMessages.benefitsItemG,
      landingMessages.benefitsItemH
    ]
  },
  strapiTeaser: {
    title: landingMessages.strapiTitle,
    text: landingMessages.strapiText,
    img: imgStrapi
  },
  contact: {
    title: landingMessages.contactTitle,
    subtitle: landingMessages.contactSubtitle,
    headline: landingMessages.contactheadline
  }
}

const LandingDesign = ({ styles }: StyleProps) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout
      footer={<Footer />}
      header={<Header withTeaser hasCustomNav customNav={<LandingpageNav />} contactBtn={<CustomContactBtn />} />}
    >
      <SEO
        title={formatMessage(messages.TitleDesign)}
        description={formatMessage(metaDescription.uxdesignDescription)}
        lang={locale}
      />
      <Landingpage data={data} styles={styles.landing} />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(LandingDesign)
